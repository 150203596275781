<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户id" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="用户openid" prop="userOpenId" >-->
<!--        <a-input v-model="form.userOpenId" placeholder="请输入用户openid" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="用户姓名" prop="userName" >
        <a-input v-model="form.userName" readOnly/>
      </a-form-model-item>
<!--      <a-form-model-item label="用户头像" prop="avatar" >-->
<!--        <a-input v-model="form.avatar" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="用户手机号" prop="userMobile" >
        <a-input v-model="form.userMobile" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="身份证号" prop="idCard" >
        <a-input v-model="form.idCard" readOnly/>
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="学历证书" prop="educationImage" >
<!--        <img v-if="form.educationImage" :src="form.educationImage" alt="学历证书" style=""/>-->
        <img style="height:200px;max-width:500px;margin-top: 5px;margin-right: 5px;" v-show="form.educationImage"
             v-for="(guildLogo,imgIndex) in (form.educationImage||'').split(',')"
             :src="guildLogo"
             preview="图片" />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="专业资格证书" prop="professionalQualificationImage" >
        <img v-if="form.professionalQualificationImage" :src="form.professionalQualificationImage" alt="学历证书" style="height:200px;max-width:500px"/>
<!--        <file-upload v-model="form.professionalQualificationImage" type="image"></file-upload>-->
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="执业证书" prop="practicingImage" >
        <img v-if="form.practicingImage" :src="form.practicingImage" alt="学历证书" style="height:200px;max-width:500px"/>
<!--        <file-upload v-model="form.practicingImage" type="image"></file-upload>-->
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="身份证人像面" prop="idCardFront" >
        <img v-if="form.idCardFront" :src="form.idCardFront" alt="学历证书" style="height:200px;max-width:500px"/>
<!--        <a-input v-model="form.idCardFront" placeholder="请输入内容" type="textarea" allow-clear />-->
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="身份证国徽面" prop="idCardBack" >
        <img v-if="form.idCardBack" :src="form.idCardBack" alt="学历证书" style="height:200px;max-width:500px"/>
<!--        <a-input v-model="form.idCardBack" placeholder="请输入内容" type="textarea" allow-clear />-->
      </a-form-model-item>

      <a-form-model-item v-show="formType == 3" label="银行卡预留手机号" prop="userBankMobile" >
        <a-input v-model="form.userBankMobile" readOnly/>
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="银行名称" prop="bankName" >
        <a-input v-model="form.bankName" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="开户行" prop="openingBank" >
        <a-input v-model="form.openingBank" readOnly/>
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="银行卡号" prop="bankCardNumber" >
        <a-input v-model="form.bankCardNumber" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="持卡人姓名" prop="bankCardName" >
        <a-input v-model="form.bankCardName" readOnly/>
      </a-form-model-item>
      <a-form-model-item v-show="formType == 2" label="审核结果" prop="auditResults" >
        <a-select placeholder="请选择审核结果" v-model="form.auditResults">
          <a-select-option :key="0" :value="0">请选择</a-select-option>
          <a-select-option :key="1" :value="1">通过</a-select-option>
          <a-select-option :key="2" :value="2">拒绝</a-select-option>
<!--          <a-select-option v-for="(d, index) in auditResultsOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-show="form.auditResults == 2" label="拒绝原因" prop="reason" >
        <a-input v-model="form.reason" placeholder="请输入拒绝原因" />
      </a-form-model-item>
<!--      <a-form-model-item label="类型" prop="isBp" >-->
<!--        <a-select placeholder="请选择类型" v-model="form.isBp">-->
<!--          <a-select-option v-for="(d, index) in isBpOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="备注" prop="remark" >
        <a-input v-model="form.remark" readOnly />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button v-show="formType == 2" type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAttest, addAttest, updateAttest } from '@/api/biz/attest'

export default {
  name: 'CreateForm',
  props: {
    auditResultsOptions: {
      type: Array,
      required: true
    },
    isBpOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        userOpenId: null,

        userName: null,

        avatar: null,

        userMobile: null,

        idCard: null,

        educationImage: null,

        professionalQualificationImage: null,

        practicingImage: null,

        idCardFront: null,

        idCardBack: null,

        userBankMobile: null,

        bankName: null,

        openingBank: null,

        bankCardNumber: null,

        bankCardName: null,

        auditResults: null,

        reason: null,

        isBp: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        userOpenId: null,
        userName: null,
        avatar: null,
        userMobile: null,
        idCard: null,
        educationImage: null,
        professionalQualificationImage: null,
        practicingImage: null,
        idCardFront: null,
        idCardBack: null,
        userBankMobile: null,
        bankName: null,
        openingBank: null,
        bankCardNumber: null,
        bankCardName: null,
        auditResults: null,
        reason: null,
        isBp: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAttest({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    handleDetail (row, ids) {
      this.reset()
      this.formType = 3
      const id = row ? row.id : ids
      getAttest({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.auditResults === 0) {
            this.$message.error("请选择审核结果")
            return false;
          } else if (this.form.auditResults === 2 && !this.form.reason) {
            this.$message.error("请输入拒绝原因")
            return false;
          }

          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAttest(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAttest(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

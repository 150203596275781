import request from '@/utils/request'


// 查询用户认证列表
export function listAttest(query) {
  return request({
    url: '/biz/user-attest/list',
    method: 'get',
    params: query
  })
}

// 查询用户认证分页
export function pageAttest(query) {
  return request({
    url: '/biz/user-attest/page',
    method: 'get',
    params: query
  })
}

// 查询用户认证详细
export function getAttest(data) {
  return request({
    url: '/biz/user-attest/detail',
    method: 'get',
    params: data
  })
}

// 新增用户认证
export function addAttest(data) {
  return request({
    url: '/biz/user-attest/add',
    method: 'post',
    data: data
  })
}

// 修改用户认证
export function updateAttest(data) {
  return request({
    url: '/biz/user-attest/edit',
    method: 'post',
    data: data
  })
}

// 删除用户认证
export function delAttest(data) {
  return request({
    url: '/biz/user-attest/delete',
    method: 'post',
    data: data
  })
}
